import React from "react";
import pdf from "../reports/Etiqueta_GamifiedDataLabeling.pdf";
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const pdfName = "Etiqueta: AI-Aided, Gamified Data Labeling to Label and Segment Data"

export const Reports = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <div>
            <DocViewer
              documents={[{ uri: pdf, fileName: pdfName}]}
              style={{ width: "100%", height: "auto"}}
              pluginRenderers={DocViewerRenderers}
              config={{ header: { disableHeader: true } }}
            />
            <p></p>
            <a href={pdf} download={pdfName} target="" rel="noopener noreferrer">
             {pdfName}
            </a>
            <p></p>
          </div>
          <div></div>
          <p>&copy; 2023 All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};