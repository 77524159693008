import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Whyus } from "./components/whyus";
import { Services } from "./components/services";
import { Howitworks } from "./components/howitworks";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { DeleteAccount } from "./components/deleteAccount";
import { PrivacyPolicy } from "./components/privacyPolicy";
import { Reports } from "./components/reports";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
      <Routes>
        <Route exact path="/" element={
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <Whyus data={landingPageData.Whyus} />
      <Services data={landingPageData.Services} />
      <Howitworks data={landingPageData.Howitworks} />
      {/* <Gallery data={landingPageData.Gallery} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
      }
      />
      <Route path="/delete_user_account" element={<DeleteAccount data={landingPageData.Contact} />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/technical_reports">
        <Route  path="/technical_reports/aidatalabel_tr_2025_0001.pdf" element={<Reports />} />
      </Route>
      </Routes>  
  );
};

export default App;
